import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const FormGroupContainer = styled(Box)(({ theme }) => ({
  ["label + &, legend + &"]: {
    marginTop: theme.spacing(1),
  },
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  [".Mui-error &"]: {
    borderColor: theme.palette.error.main,
  },
}));
