import { useChannelConfig } from "./use-channel-config";

/**
 * The default entity configured for the channel
 */
export const useDefaultEntity = () => {
  const channelConfig = useChannelConfig();
  return channelConfig.locations.find(
    ({ EntityHierarchy }) =>
      channelConfig.mappedLocationHierarchy === EntityHierarchy
  );
};
