import { useChannelConfig } from "@/hooks/use-channel-config";
import { useDefaultEntity } from "@/hooks/use-default-entity";
import { S3Image, type S3ImageProps } from "@kenai/core";
import classNames from "classnames";

export default function EntityLogo({ className = "", ...props }: S3ImageProps) {
  const { logo } = useChannelConfig();
  const defaultEntity = useDefaultEntity();
  return (
    <S3Image
      src={logo}
      width={150}
      height={150}
      alt={defaultEntity?.companyName || ""}
      className={classNames("object-contain", className)}
      {...props}
    />
  );
}
