import DoneIndicator from "@kenai/core/src/lottie/DoneIndicator";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Paper,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/nextjs";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useToast } from "../../hooks";
import { useInduction } from "./InductionProvider";

const getVolumeIcon = (volume: number) => {
  if (volume === 0) {
    return VolumeOffIcon;
  } else if (volume < 0.5) {
    return VolumeDownIcon;
  } else {
    return VolumeUpIcon;
  }
};

const toReadableTime = function (value: any) {
  let sec_num = parseInt(value, 10); // don't forget the second param
  let hours: any = Math.floor(sec_num / 3600);
  let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
  let seconds: any = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (hours <= 0) {
    hours = undefined;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return [hours, minutes, seconds].filter(Boolean).join(":");
};

const launchIntoFullscreen = function (element: HTMLDivElement) {
  if (typeof window !== "undefined" && "requestFullscreen" in element) {
    element.requestFullscreen();
  }
};

const exitFullscreen = function () {
  if (typeof window !== "undefined" && "exitFullscreen" in document) {
    document.exitFullscreen();
  }
};

const VolumeSlider = ({
  volume = 0,
  onSetVolume,
}: {
  volume: number;
  onSetVolume: (volume: number) => void;
}) => {
  const [hovered, setHovered] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [waitingLeave, setWaitingLeave] = useState(false);

  const toggleSliderVisible = () => {
    setWaitingLeave(false);
    setHovered((curr) => !curr);
  };

  const handleChange = (value: number) => {
    setCapturing(true);
    onSetVolume(value / 100);
  };

  const handleChangeCommitted = useCallback(() => {
    setCapturing(false);
    if (waitingLeave) {
      setHovered(false);
      setWaitingLeave(false);
    }
  }, [waitingLeave]);

  const handleLeave = useCallback(() => {
    if (!capturing) {
      setHovered(false);
    } else {
      setWaitingLeave(true);
    }
  }, [capturing]);

  const Icon = getVolumeIcon(volume);

  return (
    <div
      onMouseEnter={() => {
        setWaitingLeave(false);
        setHovered(true);
      }}
      onMouseLeave={handleLeave}
      className={"flex space-x-4 items-center w-[100px] h-[48px]"}
    >
      <Icon onClick={toggleSliderVisible} />

      <Slider
        className={classNames(!hovered ? "hidden" : "")}
        aria-label="Volume"
        value={volume * 100}
        onChange={(e, value) => handleChange(value as number)}
        onChangeCommitted={handleChangeCommitted}
      />
    </div>
  );
};

const videoUtils = {
  getProgress: (video?: HTMLVideoElement) => {
    if (!video) {
      return 0;
    }
    const progress = (video.currentTime / video.duration) * 100;
    return progress;
  },
  getBufferValue: (video?: HTMLVideoElement) => {
    if (!video) {
      return 0;
    }
    try {
      const buffered = video.buffered?.end?.(0);
      const duration = video.duration;
      const bufferedProgress = (buffered / duration) * 100;
      return bufferedProgress;
    } catch (error) {
      return 0;
    }
  },
};

const InductionVideo = () => {
  const videoRef = useRef<HTMLVideoElement>();
  const containerRef = useRef(null);
  const theme = useTheme();
  const [volume, setVolume] = useState(1);
  const toast = useToast();

  const [videoProgress, setProgress] = useState(0);
  const [videoBuffer, setVideoBuffer] = useState(0);
  const [videoDuration, setDuration] = useState("");
  const [videoStatus, setStatus] = useState<"paused" | "ended" | "playing">(
    "paused"
  );
  const [isLoading, setLoading] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const induction = useInduction();
  const completed = induction.state.videoCompleted;
  const inductionVideoLink = induction.inductionVideoLink;
  const [videoLink, setVideoLink] = useState(inductionVideoLink);
  const [panelVisible, setPanelVisible] = useState(true);

  useEffect(() => {
    setVideoLink(inductionVideoLink);
  }, [inductionVideoLink]);

  const isPlaying = videoStatus === "playing";
  const isComplete = completed && !isPlaying;

  useEffect(() => {
    if (videoStatus !== "playing") {
      setPanelVisible(true);
    }
  }, [videoStatus]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (videoProgress === 100) {
      setStatus("ended");
      if (!completed) {
        induction.setCompleted(true, "video");
      }
    }
  }, [videoProgress]);

  useEffect(() => {
    const video = videoRef.current;
    const progressEvent = () => {
      if (!video) return;
      setProgress(videoUtils.getProgress(video));
      setDuration(
        `${toReadableTime(video.currentTime)} / ${toReadableTime(
          video.duration
        )}`
      );
    };
    const bufferEvent = () => {
      if (!video) return;
      setVideoBuffer(videoUtils.getBufferValue(video));
    };
    if (typeof window !== "undefined" && video) {
      video.addEventListener("timeupdate", progressEvent);
      video.addEventListener("progress", bufferEvent);
    }

    return () => {
      if (typeof window !== "undefined" && video) {
        video.removeEventListener("timeupdate", progressEvent);
        video.addEventListener("progress", bufferEvent);
      }
    };
  }, []);

  const handleVideoLoaded = (event: any) => {
    const video = event.target as HTMLVideoElement;
    if (isNaN(video.duration)) {
      return; // duration is NotaNumber at Beginning.
    }
    setDuration(
      `${toReadableTime(video.currentTime)} / ${toReadableTime(video.duration)}`
    );
    setLoading(false);
  };

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (!video) return;
    if (videoStatus !== "playing") {
      video.play();
      setStatus("playing");
    } else {
      video.pause();
      setStatus("paused");
    }
  };

  const handleToggleFullscreen = () => {
    if (containerRef.current) {
      if (!fullScreen) {
        setFullScreen(true);
        launchIntoFullscreen(containerRef.current);
      } else {
        setFullScreen(false);
        exitFullscreen();
      }
    }
  };

  const ActionIcon = isPlaying ? PauseIcon : PlayArrowIcon;
  const ActionLabel = !isPlaying ? "Play" : "Pause";

  const backgroundColor = theme.palette.grey?.["900"];

  return (
    <Paper
      ref={containerRef}
      sx={{
        backgroundColor,
      }}
      className={classNames(
        "relative overflow-hidden",
        !fullScreen && "rounded-[5px]"
      )}
      onMouseOver={() => setPanelVisible(true)}
      onMouseLeave={() => {
        if (isPlaying) {
          setPanelVisible(false);
        }
      }}
      onTouchStart={() => {
        setPanelVisible(true);
        const previousStateWasPlaying = videoStatus !== "playing";
        if (previousStateWasPlaying) {
          setTimeout(() => setPanelVisible(false), 2000);
        }
      }}
    >
      <Sentry.ErrorBoundary
        fallback={<p>An error has occurred while loading the video</p>}
        showDialog
      >
        <Box
          component="video"
          ref={videoRef}
          width="100%"
          controls={false}
          onClick={handlePlayPause}
          onLoadedData={handleVideoLoaded}
          onCanPlayThrough={() => {
            setVideoBuffer(100);
          }}
          sx={{
            height: fullScreen ? "100vh" : "auto",
          }}
          className={classNames("mb-[-6px]", {
            "rounded-[5px]": !fullScreen,
            "pb-[48px]": fullScreen,
          })}
          onWaiting={() => {
            setLoading(true); // Buffer States
          }}
          onPlaying={() => {
            setLoading(false); // Buffer States
          }}
        >
          <source
            src={videoLink}
            type="video/mp4"
            onError={() => {
              toast("Could not load video", {
                variant: "error",
                autoHideDuration: 3000,
              });
            }}
          />

          {"Your browser doesn't support videos."}
        </Box>
      </Sentry.ErrorBoundary>
      {isLoading && (
        <div
          className={classNames(
            "w-full h-full absolute top-0 bottom-0 left-0 right-0 flex place-items-center justify-center pointer-events-none"
          )}
        >
          <CircularProgress />
        </div>
      )}

      <Box
        sx={{
          color: theme.palette.grey?.["100"],
        }}
        className={classNames(
          "controls h-[48px] bottom-0 left-0 right-0 absolute transition-all ease-in-out duration-500",
          panelVisible || fullScreen ? "bottom-0" : "bottom-[-48px]"
        )}
      >
        <div className="bg-gradient-to-t from-slate-900/60 absolute h-[calc(100%+48px)] w-full bottom-0 pointer-events-none" />
        <div className="px-2">
          <LinearProgress
            variant="buffer"
            value={videoProgress}
            valueBuffer={videoBuffer}
            sx={{
              [".MuiLinearProgress-dashedColorPrimary"]: {
                backgroundColor: "rgba(255,255,255,.2)",
                animation: "none",
                backgroundImage: "none",
              },
              [".MuiLinearProgress-bar2Buffer"]: {
                backgroundColor: "rgba(255,255,255,.4)",
              },
            }}
          />

          <Box className="flex justify-between items-center h-[44px]">
            <div className="flex items-center z-10">
              <IconButton
                aria-label={ActionLabel}
                onClick={handlePlayPause}
                sx={{
                  ["&:hover"]: {
                    color: (theme) => theme.palette.primary.main,
                  },
                  color: "white",
                }}
              >
                <ActionIcon />
              </IconButton>
              <VolumeSlider volume={volume} onSetVolume={setVolume} />
            </div>
            <div className="flex items-center space-x-2 z-10">
              <Typography variant="caption">{videoDuration}</Typography>
              <IconButton
                aria-label="Toggle Fullscreen"
                onClick={handleToggleFullscreen}
                sx={{
                  ["&:hover"]: {
                    color: (theme) => theme.palette.primary.main,
                  },
                  color: "white",
                }}
              >
                <FullscreenIcon />
              </IconButton>
            </div>
          </Box>
        </div>
      </Box>
      <Box
        className={classNames("absolute top-[50%] left-[50%]", {
          hidden: isPlaying || !isComplete,
        })}
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <DoneIndicator />
      </Box>
    </Paper>
  );
};

export default InductionVideo;
