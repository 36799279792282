import { ProviderContext, useSnackbar } from "notistack";

interface Toast {
  (
    ...args: Parameters<ProviderContext["enqueueSnackbar"]>
  ): ProviderContext["enqueueSnackbar"];
  closeToast: ProviderContext["closeSnackbar"];
}

export function useToast(): Toast {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const toast = enqueueSnackbar as any;
  toast.closeToast = closeSnackbar;

  return toast;
}
