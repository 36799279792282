import { useEffect, useRef, useState } from "react";
import type {
  LottiePlayer,
  AnimationConfigWithPath,
  AnimationConfigWithData,
} from "lottie-web";

type AnimationProps = Omit<
  AnimationConfigWithPath | AnimationConfigWithData,
  "container"
>;

export const Animation = (lottieOptions: AnimationProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import("lottie-web").then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        ...lottieOptions,
      });

      return () => animation.destroy();
    }
  }, [lottie, lottieOptions]);

  return <div ref={ref} />;
};
