import {
  FormControl,
  FormHelperText,
  Slider,
  SliderProps,
} from "@mui/material";
import { Controller, ControllerProps } from "react-hook-form";
import {
  type ControlledFieldKeys,
  type FormInputProps,
} from "./FormInputProps";
import { OutlinedInputLabel } from "../input/OutlinedInput";
import type { FieldValues, FieldPath } from "react-hook-form";

type InputComponentProps = SliderProps;
type BaseProps = Omit<
  InputComponentProps,
  keyof FormInputProps | "id" | ControlledFieldKeys
>;

export interface FormInputSliderProps extends BaseProps {
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
  defaultValue?: InputComponentProps["defaultValue"];
  valueLabelSize?: number;
}

export const FormInputSlider = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  placeholder,
  readOnly,
  required,
  min,
  max,
  step,
  unit,
  defaultValue,
  valueLabelSize = 32,
  ...inputProps
}: FormInputSliderProps & FormInputProps<TFieldValues, TFieldPath>) => {
  const marks = getMarks(min, max, unit);
  const hasMarks = Boolean(marks?.[0]);

  const rules: ControllerProps<TFieldValues, TFieldPath>["rules"] = {};
  if (required) {
    rules.required = "Required";
  }
  if (min) {
    rules.min = min;
  }
  if (max) {
    rules.max = max;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const helperText = error ? error.message : null;
        return (
          <FormControl
            variant="standard"
            error={!!error}
            disabled={readOnly}
            fullWidth={true}
            hidden={inputProps.hidden}
            sx={
              hasMarks
                ? {
                    pb: 1, // adds additional spacing for mark labels
                  }
                : undefined
            }
          >
            <OutlinedInputLabel
              shrink
              id={`${name}-slider-label`}
              required={required}
              className={
                inputProps.valueLabelDisplay === "on" ? "pb-[40px]" : undefined
              }
            >
              {label}
            </OutlinedInputLabel>
            <Slider
              {...inputProps}
              {...field}
              value={field.value || min || 0}
              aria-labelledby={`${name}-slider-label`}
              step={step}
              min={min}
              max={max}
              getAriaValueText={(value) => getAriaValueText(value, unit)}
              valueLabelDisplay={inputProps.valueLabelDisplay || "auto"}
              marks={marks}
              sx={{
                ["label + &"]: {
                  mt: 1, // ensure label does not overlap the slider
                },
                ...(hasMarks && {
                  width: "90%", //TODO: find a better solution - avoids the start and end mark to pass the form width
                  mx: "auto",
                }),
                "& .MuiSlider-valueLabel": {
                  lineHeight: 1.2,
                  fontSize: 12,
                  background: "unset",
                  padding: 0,
                  width: valueLabelSize,
                  height: valueLabelSize,
                  borderRadius: "50% 50% 50% 0",
                  backgroundColor: (theme) => theme.palette.primary.dark,
                  transformOrigin: "bottom left",
                  transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
                  "&:before": { display: "none" },
                  "&.MuiSlider-valueLabelOpen": {
                    transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
                  },
                  "& > *": {
                    transform: "rotate(45deg)",
                  },
                },
              }}
            />
            {helperText && (
              <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

function getAriaValueText(value: number, unit?: string) {
  return unit ? `${value}${unit}` : value.toString();
}

const getMarks = (min?: number, max?: number, unit?: string) => {
  if (!min || !max) {
    return undefined;
  }

  const createMark = (value: number) => ({
    value,
    label: getAriaValueText(value, unit),
  });

  const start = createMark(min);
  const midpoint = createMark((min + max) / 2);
  const end = createMark(max);

  return [start, midpoint, end];
};
