import { createContext, FC, useContext, useState } from "react";

export type InductionQuestion = {
  EntityHierarchy: string;
  questionId: string;
  sortIndex: number;
  label: string;
  disabled: boolean;
  deleted: boolean;
  inputSettings: any;
  staticDropDownListItems: {
    correct: boolean;
    dataKey: string;
    text: string;
  }[];
};

export type Induction = {
  inductionVideoLink: string;
  version: string | number;
  inductionQuestions?: InductionQuestion[];
};

type Loading = "idle" | "pending" | "completed" | "skipped";

export interface InductionContextValues extends Induction {
  state: typeof initialState;
  defaultValue?: {
    questions: Record<string, string>;
  };
  setInduction: (induction: Induction) => void;
  setOpen: (visible: boolean) => void;
  setLoading: (loading: Loading) => void;
  setCompleted: (
    completed: boolean,
    type: "questions" | "video",
    data?: Record<string, string>
  ) => void;
  setSkipped: (skipped: boolean, type: "questions" | "video") => void;
  handleNext: () => void;
}

export const InductionContext = createContext<InductionContextValues>(
  null as any
);

const initialInduction: Induction = {
  inductionVideoLink: "",
  version: "" as string | number,
  inductionQuestions: [],
};
const initialState = {
  open: false,
  loading: "idle" as Loading,
  videoCompleted: false,
  videoSkipped: false,
  questionsCompleted: false,
  questionsSkipped: false,
};

export interface InductionProviderProps {
  defaultValue?: InductionContextValues["defaultValue"];
  initialInduction?: typeof initialInduction;
  initialState?: Partial<typeof initialState>;
  onCompletedVideo?: () => void;
  onCompletedQuestions?: (answers: Record<string, string>) => void;
  onSkippedVideo?: () => void;
  onSkippedQuestions?: () => void;
  onNext?: () => void;
}

const saveParseState = (state?: Induction) => (fallback: Induction) => {
  if (!state) {
    return fallback;
  }

  return {
    ...state,
    inductionQuestions: state.inductionQuestions || [],
  };
};

export const InductionProvider: FC<InductionProviderProps> = (props) => {
  const [induction, setInduction] = useState<Induction>(
    saveParseState(props.initialInduction)(initialInduction)
  );
  const [state, setState] = useState({
    ...initialState,
    ...props.initialState,
  });

  const setOpen: InductionContextValues["setOpen"] = (visible) => {
    setState((curr) => ({ ...curr, open: visible }));
  };

  const setLoading: InductionContextValues["setLoading"] = (loading) => {
    setState((curr) => ({ ...curr, loading }));
  };

  const setCompleted: InductionContextValues["setCompleted"] = (
    completed,
    type,
    data
  ) => {
    setState((curr) => ({ ...curr, [`${type}Completed`]: completed }));
    if (completed) {
      setOpen(false);
      if (type === "video") {
        props.onCompletedVideo?.();
      } else {
        props.onCompletedQuestions?.(data ?? {});
      }
    }
  };

  const setSkipped: InductionContextValues["setSkipped"] = (skipped, type) => {
    setState((curr) => ({ ...curr, [`${type}Skipped`]: skipped }));
    if (skipped) {
      if (type === "video") {
        props.onSkippedVideo?.();
      } else {
        props.onSkippedQuestions?.();
      }
    }
  };

  const handleNext = () => {
    props.onNext?.();
  };

  return (
    <InductionContext.Provider
      value={{
        ...induction,
        defaultValue: props.defaultValue,
        setInduction,
        state,
        setOpen,
        setLoading,
        setCompleted,
        setSkipped,
        handleNext,
      }}
    >
      {props.children}
    </InductionContext.Provider>
  );
};

export const InductionConsumer = InductionContext.Consumer;

export const useInduction = () => {
  return useContext(InductionContext);
};
