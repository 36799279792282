export type { FormInputProps, ControlledFieldKeys } from "./FormInputProps";

import { FormInputText, type FormInputTextProps } from "./FormInputText";
import { FormInputNumber, type FormInputNumberProps } from "./FormInputNumber";
import {
  FormInputDropdown,
  type FormInputDropdownProps,
} from "./FormInputDropdown";
import {
  FormInputCheckbox,
  type FormInputCheckboxProps,
} from "./FormInputCheckbox";
import { FormInputSlider, type FormInputSliderProps } from "./FormInputSlider";
import { FormInputRadio, type FormInputRadioProps } from "./FormInputRadio";

export {
  FormInputText,
  type FormInputTextProps,
  FormInputNumber,
  type FormInputNumberProps,
  FormInputDropdown,
  type FormInputDropdownProps,
  FormInputCheckbox,
  type FormInputCheckboxProps,
  FormInputSlider,
  type FormInputSliderProps,
  FormInputRadio,
  type FormInputRadioProps,
};

export const FormInput = {
  Text: FormInputText,
  Number: FormInputNumber,
  Dropdown: FormInputDropdown,
  Checkbox: FormInputCheckbox,
  Slider: FormInputSlider,
  Radio: FormInputRadio,
} as const;
