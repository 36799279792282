import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  ListItem,
  MenuItem,
  Select as MuiSelect,
  Typography,
  type SelectProps as MuiSelectProps,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import OutlinedInput, { OutlinedInputLabel } from "./OutlinedInput";

type Option = {
  label: string;
  value: string;
};

interface MultiSelectProps<T extends string[] = string[]>
  extends Omit<MuiSelectProps<T>, "onChange"> {
  multiple: true;
  onChange?: (value: T, node: any) => void;
}

interface SingleSelectProps<T extends string = string>
  extends Omit<MuiSelectProps<T>, "onChange"> {
  onChange?: (value: T, node: any) => void;
}

interface BaseSelectProps {
  options: Option[];
  tag?: boolean;
  helperText?: string;
  value?: string | string[];
}

export type SelectProps = (MultiSelectProps | SingleSelectProps) &
  BaseSelectProps;

function renderValue(options: Option[], placeholder: string, tag?: boolean) {
  return (value?: any) => {
    if (value === undefined || value === "") {
      return placeholder;
    }

    const isMultipleSelect = Array.isArray(value);

    let selectedOptions = [] as Option[];

    if (isMultipleSelect) {
      selectedOptions = options.filter((option) =>
        value.includes(option.value)
      );
    } else {
      selectedOptions = options.filter((option) => value === option.value);
    }

    if (tag) {
      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selectedOptions.map((option) => (
            <Chip key={option.value} label={option.label} />
          ))}
        </Box>
      );
    }

    return selectedOptions.map((option) => option.label).join(", ");
  };
}

function createOption({
  multiple = false,
  checked = false,
  disabled = false,
} = {}) {
  return (option: Option) => {
    return (
      <MenuItem
        key={option.value}
        value={option.value}
        disabled={Boolean(disabled)}
      >
        {multiple && <Checkbox checked={checked} />}
        {option.label}
      </MenuItem>
    );
  };
}

export const Select = forwardRef<any, SelectProps>((baseProps, ref) => {
  const {
    name,
    label,
    options,
    placeholder = "Select an option",
    readOnly,
    error,
    required,
    tag,
    helperText,
    disabled,
    ...props
  } = baseProps;

  const [open, setOpen] = useState(props.open || false);
  const [value, setValue] = useState<string | string[]>(
    props.value ?? props.multiple ? [] : ""
  );

  useEffect(() => {
    const fallbackValue = props.multiple ? [] : "";
    setValue(props.value || fallbackValue);
  }, [props.value, props.multiple]);

  const onOpen: SelectProps["onOpen"] = (event) => {
    setOpen(true);
    if ("onOpen" in props && props.onOpen) {
      props.onOpen(event);
    }
  };

  const onClose: SelectProps["onClose"] = (event) => {
    setOpen(false);
    if ("onClose" in props && props.onClose) {
      props.onClose(event);
    }
  };

  const onChange = (value: any, child: any) => {
    setValue(value);
    if ("onChange" in props && props.onChange) {
      props.onChange(value, child);
    }
  };

  const isMultiSelect = props.multiple;
  const showPlaceholder = !value || (isMultiSelect && value.length === 0);

  return (
    <FormControl fullWidth error={!!error} disabled={readOnly}>
      {label && (
        <OutlinedInputLabel shrink id={`${name}-label`} required={required}>
          {label}
        </OutlinedInputLabel>
      )}
      <MuiSelect
        ref={ref}
        {...props}
        disabled={Boolean(disabled)}
        value={value}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        onChange={(event, child: any) => onChange(event.target.value, child)}
        displayEmpty={!!placeholder}
        renderValue={renderValue(options, placeholder, tag)}
        input={<OutlinedInput />}
        className={showPlaceholder ? "placeholder" : ""}
        aria-labelledby={`${name}-label`}
        fullWidth
        sx={{
          ...props.sx,
          "& .MuiInputBase-inputMultiline": {
            whiteSpace: "inherit!important",
          },
          "&.placeholder .MuiInputBase-input": {
            color: (theme) => theme.palette.neutral?.["400"],
          },
        }}
      >
        {createOption({ disabled: true })({ value: "", label: placeholder })}
        {options.map((option) => {
          let checked = value === option.value;

          if (Array.isArray(value)) {
            checked = value.includes?.(option.value);
          }

          return createOption({
            multiple: props.multiple,
            checked,
          })(option);
        })}
        {isMultiSelect && [
          <Divider key="divider" />,
          <ListItem key="footer">
            <Typography variant="caption">
              {value?.length ?? 0} of {options.length}
            </Typography>
            <div className="flex flex-grow justify-end space-x-2">
              <Button variant="contained" onClick={() => setOpen(false)}>
                Done
              </Button>
            </div>
          </ListItem>,
        ]}
      </MuiSelect>
      {helperText && (
        <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
});
