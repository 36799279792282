import { FormControl, FormHelperText, OutlinedInputProps } from "@mui/material";
import { Controller, ControllerProps } from "react-hook-form";
import type { ControlledFieldKeys, FormInputProps } from "./FormInputProps";
import OutlinedInput, { OutlinedInputLabel } from "../input/OutlinedInput";
import type { FieldValues, FieldPath } from "react-hook-form";

type InputComponentProps = OutlinedInputProps;
type BaseProps = Omit<
  InputComponentProps,
  keyof FormInputProps | "id" | ControlledFieldKeys
>;
export interface FormInputNumberProps extends BaseProps {
  defaultValue?: InputComponentProps["defaultValue"];
}

/** Ensure that the output is a number value */
const transform = {
  input: (value: any) => {
    return isNaN(value) || value === 0 ? "" : value.toString();
  },
  output: (e: any) => {
    const output = parseInt(e.target.value, 10);
    return isNaN(output) ? 0 : output;
  },
};

export const FormInputNumber = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  placeholder,
  readOnly,
  required,
  defaultValue,
  ...inputProps
}: FormInputNumberProps & FormInputProps<TFieldValues, TFieldPath>) => {
  const fullWidth = "fullWidth" in inputProps ? inputProps.fullWidth : true;

  const rules: ControllerProps<TFieldValues, TFieldPath>["rules"] = {};
  if (required) {
    rules.required = "Required";
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const helperText = error ? error.message : null;
        return (
          <FormControl
            variant="standard"
            error={!!error}
            disabled={readOnly}
            fullWidth={fullWidth}
            hidden={inputProps.hidden}
          >
            <OutlinedInputLabel
              shrink
              htmlFor={`${name}-input`}
              required={required}
            >
              {label}
            </OutlinedInputLabel>
            <OutlinedInput
              {...inputProps}
              {...field}
              type="number"
              inputMode="numeric"
              onChange={(e) => field.onChange(transform.output(e))}
              id={`${name}-input`}
              placeholder={placeholder}
              readOnly={readOnly}
              value={transform.input(field.value)}
            />
            {helperText && (
              <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
