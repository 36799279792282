import { useInduction } from "@/hooks/use-induction";
import { submitInduction } from "@/slices/induction";
import { stepper } from "@/slices/stepper";
import { useDispatch } from "@/store";
import { Induction, useToast } from "@kenai/core";
import React, { FC } from "react";
import StepperLayout from "../StepperLayout";

const Layout: FC = (props) => {
  return (
    <StepperLayout className="induction-layout">{props.children}</StepperLayout>
  );
};

export default function InductionView() {
  const induction = useInduction();
  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = async () => {
    const submitAction = await dispatch(submitInduction());
    const isFulfilled = submitInduction.fulfilled.match(submitAction);
    const isSuccess =
      isFulfilled && submitAction.payload.key === "OPERATION_PROCESSED";
    const isSubmissionError =
      isFulfilled && submitAction.payload.key === "OPERATION_FAILED";
    const isGenericError =
      isFulfilled && submitAction.payload.key === "GENERIC_ERROR";

    if (isSuccess || isSubmissionError) {
      // We handle these messages with validationError or static success message on the summary screen
      dispatch(stepper.nextStep());
      return;
    }

    const genericError =
      "We ran into an unknown error while submitting your request, please contact support if this persists.";

    toast(
      isGenericError
        ? submitAction.payload.error?.message ?? genericError
        : genericError,
      {
        variant: "error",
        autoHideDuration: 5000,
      }
    );
  };

  return (
    <Layout>
      <Induction
        initialInduction={induction.inductionData}
        nextButtonText="Submit"
        onNext={handleSubmit}
        loading={induction.loading === "pending"}
      />
    </Layout>
  );
}
