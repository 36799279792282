import { zodResolver } from "@hookform/resolvers/zod";
import { FormInput, useMediaQuery, useToast } from "@kenai/core";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { useCallback } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { z } from "zod";
import { useInduction, type Induction } from "./InductionProvider";

const resolveQuestion = (
  question: NonNullable<Induction["inductionQuestions"]>[number],
  control: Control<FieldValues, object>,
  disabled: boolean
) => {
  return (
    <FormInput.Radio
      key={question.questionId}
      control={control}
      name={question.questionId}
      label={question.label}
      required
      disabled={disabled}
      options={question.staticDropDownListItems.map((option) => ({
        value: option.dataKey,
        label: option.text,
      }))}
    />
  );
};

const useSchema = () => {
  const induction = useInduction();
  const inductionQuestions = induction.inductionQuestions;

  const fields: Record<string, any> = {};
  const fieldsWithRegex: Record<string, any> = {};

  if (inductionQuestions?.[0]) {
    inductionQuestions.forEach((option) => {
      const field = z.string().nonempty({
        message: "The question is required",
      });

      fields[option.questionId] = field;

      const correctAnswer = option.staticDropDownListItems.find((answer) =>
        Boolean(answer.correct)
      );
      if (correctAnswer?.dataKey) {
        const regex = new RegExp(correctAnswer?.dataKey);
        fieldsWithRegex[option.questionId] = field.regex(regex);
      }
    });
  }

  return {
    filledSchema: z.object(fields),
    regexSchema: z.object(fieldsWithRegex),
  };
};

export default function InductionQuestions() {
  const { filledSchema, regexSchema } = useSchema();
  const { state, ...induction } = useInduction();
  const isOpen = state.open;
  const videoCompleted = state.videoCompleted;
  const questionsCompleted = state.questionsCompleted;
  const inductionQuestions = induction.inductionQuestions ?? [];
  const { handleSubmit, control } = useForm({
    defaultValues: induction.defaultValue?.questions,
    resolver: zodResolver(filledSchema),
  });
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const toast = useToast();

  const handleOpen = (visible: boolean) => {
    induction.setOpen(visible);
  };
  const onSubmit = useCallback(
    (data) => {
      const result = regexSchema.safeParse(data);
      if (result.success) {
        induction.setCompleted(true, "questions", result.data);

        toast("Induction questions completed", {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        toast("You got one or more questions incorrect. Please retry.", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [induction, regexSchema, toast]
  );

  const OpenQuestionButton = (props: {
    completed: boolean;
    videoCompleted: boolean;
    skipped: boolean;
  }) => {
    const buttonText = props.completed
      ? "Questionnaire completed"
      : props.skipped
      ? "Questionnaire skipped, Start Now?"
      : "Start induction questionnaire";

    const button = (
      <Button
        variant={videoCompleted ? "contained" : "outlined"}
        onClick={() => handleOpen(true)}
        startIcon={props.completed ? <CheckIcon /> : null}
        disabled={!props.videoCompleted}
      >
        {buttonText}
      </Button>
    );

    if (!props.videoCompleted) {
      return (
        <Tooltip title="You need to complete the induction video first">
          <div>{button}</div>
        </Tooltip>
      );
    }
    return button;
  };

  return (
    <>
      <OpenQuestionButton
        completed={questionsCompleted}
        videoCompleted={videoCompleted}
        skipped={state.questionsSkipped}
      />

      <Dialog
        open={isOpen}
        onClose={() => handleOpen(false)}
        fullScreen={fullScreen}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Induction questions</DialogTitle>
          <DialogContent dividers>
            <div className="grid grid-cols-1 divide-y gap-2">
              {inductionQuestions.map((question) =>
                resolveQuestion(question, control, questionsCompleted)
              )}
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => handleOpen(false)}>
              {questionsCompleted ? "Close" : "Cancel"}
            </Button>
            {!questionsCompleted && (
              <LoadingButton
                loading={state.loading === "pending"}
                type="submit"
              >
                Submit
              </LoadingButton>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
