import { OutlinedInput as MuiOutlinedInput, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

const OutlinedInput = styled(MuiOutlinedInput)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
}));

export const OutlinedInputLabel = styled(InputLabel)(() => ({
  "&.MuiInputLabel-shrink": {
    whiteSpace: "inherit",
    position: "relative",
    textAlign: "justify",
    width: "100%",
    transform: "none",
    fontSize: "calc(1em * 0.75)", // When the label is shrinked it scaled down to 75% of the original size which causes a "white-space" margin, this allows us to scale the same label sizes but without the white-space
  },
}));

export default OutlinedInput;
