import Induction from "@/components/induction/induction";
import { useStepper } from "@/hooks/use-step";
import API from "@/lib/api";
import { API_ENDPOINT_COOKIE } from "@/lib/constants";
import { getLookupValue } from "@/lib/getLookupValue";
import { tokenHandler } from "@/lib/token-handler";
import { Result, type NextPage } from "@kenai/core";
import { createDebugger } from "@kenai/utils";
import { decomposeColor } from "@mui/material";
import type { GetServerSidePropsContext } from "next";
import dynamic from "next/dynamic";
import Head from "next/head";
import { info } from "@kenai/utils";
import { getEndpoint } from "@/lib/getEndpoint";

const IdentificationSelector = dynamic(
  () => import("@/components/identification-selector")
);
const Agreement = dynamic(() => import("@/components/agreement"));
const Authentication = dynamic(
  () => import("@/components/authentication/authentication")
);
const Summary = dynamic(() => import("@/components/summary/summary"));
const correlationIdentifier = `${
  Date.now() - Math.floor(Math.random() * 10000)
}`;
const debug = (...args: any[]) => {
  return createDebugger("page", true)(`[${correlationIdentifier}]`, ...args);
};
type PageProps = Awaited<ReturnType<typeof getServerSideProps>>["props"];
const Page: NextPage<PageProps> = (props) => {
  const stepper = useStepper();

  if (stepper.currentStep === "agreement") {
    return <Agreement />;
  }

  if (stepper.currentStep === "authentication") {
    return <Authentication />;
  }

  if (stepper.currentStep === "induction") {
    return <Induction />;
  }

  if (stepper.currentStep === "summary") {
    return <Summary />;
  }

  throw new Error("App should never get here");
};
Page.getLayout = (page) => {
  const props = page.props as PageProps;

  if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
    info(props);
  }
  if ("errorMessage" in props && props.errorMessage) {
    return <Result adornment={500} title="Oops" message={props.errorMessage} />;
  }
  if ("uisDualModeEnabled" in props && props.uisDualModeEnabled === true) {
    return (
      <IdentificationSelector
        identifierButtonText={props.uisIdentifiersButtonText}
        ssoButtonText={props.uisSsoButtonText}
      />
    );
  }

  let title = "Kenai";
  if ("channelConfig" in props) {
    title = props.channelConfig?.locations?.[0].companyName || "Kenai";
  }
  return (
    <>
      <Head>
        <title>Induction - {title}</title>
      </Head>

      {page}
    </>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const cookies = context.req.cookies;
  const query = context.query as Record<string, string | undefined>;
  const endpoint =
    cookies[API_ENDPOINT_COOKIE] || (await getEndpoint(context.req));
  const api = new API(endpoint);
  const host = context.req.headers.host as string;
  const lookupValue = getLookupValue(host);

  try {
    const result = await api.getChannelConfig(lookupValue);

    if (result.key !== "OPERATION_PROCESSED") {
      debug("Channel config retrieval failed");
      return {
        props: {
          errorMessage: result.processingResponse?.validationFailure,
        },
      };
    }

    debug("getChannelConfig:key", result.key);
    const channelConfig = result.processingResponse?.channelConfig;
    if (!channelConfig) {
      throw new Error("Missing channel config");
    }

    const { locations = [], serverSideOnlyParams = {} } = channelConfig;

    const defaultLocation = locations[0];

    const primaryPalette = defaultLocation._palette;

    if (!primaryPalette) {
      throw new Error("Missing primary palette on default location");
    }

    const rgba = decomposeColor(primaryPalette.main).values;
    const twPrimary = rgba.slice(0, rgba.length - 1).join(" ");
    const defaultLocale = defaultLocation.defaultLocale;

    // used for all the returns of valid requests
    const sharedConfig = {
      channelConfig,
      primaryPalette,
      defaultLocale,
      twPrimary,
    };

    if (serverSideOnlyParams) {
      // cleanup as this should only exist on the server
      delete channelConfig.serverSideOnlyParams;
    }

    const ssoToken = tokenHandler.retrieveTokenFromCookie(cookies["kca"]);
    const { uis, token, code } = query;

    // check user identification settings
    const { userIdentificationSettings } =
      serverSideOnlyParams?.SSOSettings ?? {};

    const isFirstLoad = !uis && !code && !token;
    if (userIdentificationSettings?.dualModeEnabled && isFirstLoad) {
      debug("App initialization with dual mode");
      return {
        props: {
          ...sharedConfig,
          uisDualModeEnabled: true,
          uisSsoButtonText: userIdentificationSettings?.ssoButtonText ?? "SSO",
          uisIdentifiersButtonText:
            userIdentificationSettings?.identifiersButtonText ?? "IDENTIFIERS",
        },
      };
    }

    const { ssoRedirectURL } = serverSideOnlyParams;

    if (ssoRedirectURL) {
      debug("SSO TRACING", {
        uis,
        token: !token,
        ssoRedirectURL: ssoRedirectURL,
        ...(ssoToken && {
          ssoTokenLength: ssoToken.value?.length ?? 0,
          ssoTokenIsValid: ssoToken.isValid,
        }),
      });
    }

    const isNotIdentifiers = !token && uis !== "identifiers";
    const isRedirect = ssoRedirectURL && !ssoToken;
    const isInvalidToken = ssoToken && !ssoToken.isValid;
    const isSSOAuthFlow = isNotIdentifiers && (isRedirect || isInvalidToken); // TODO verify checks - this variable name does not make sense @WillemPCIO help 😸

    if (!isSSOAuthFlow) {
      debug("Proceeding to app flow");
      return {
        props: {
          ...sharedConfig,
          isSSOFlow: !!ssoToken && uis !== "identifiers" && !token,
        },
      };
    }

    const { error = "", error_description = "" } = query;
    const hasSSOError = Boolean(
      ["server_error", "redirect_uri_mismatch"].some((e) =>
        error.includes(e)
      ) && error_description
    );
    const showErrorMessage = !code && hasSSOError;
    if (showErrorMessage) {
      debug("SSO Auth error", {
        error,
        error_description,
      });
      debugger;
      let errorMessage =
        "There is an error with your single sign-on - please contact your support department to verify your SSO setup. - GENERIC ERROR";
      if (error_description.includes("Error in SAML response processing")) {
        errorMessage =
          "There is an error with your single sign-on - please contact your support department to verify your SSO setup.";
      } else if (error_description.includes("AADB2C")) {
        errorMessage = `There is an error with your single sign-on - please contact your support department to verify your SSO setup.\nError details - ${error_description}`;
      }
      return {
        props: {
          ...sharedConfig, // TODO - @WillemPCIO Should we return this on SSO error? (for theming)
          errorMessage,
        },
      };
    }

    if (!code || !hasSSOError) {
      debug("Proceeding to SSO redirect");
      return {
        redirect: {
          destination: ssoRedirectURL,
          permanent: false,
        },
        props: {}, // keeping props for the inferred type
      };
    }

    debug("Proceeding with code resolution");
    const activeTokenResult = await api.getAccessTokenForSSOAuthCode(
      code,
      lookupValue
    );

    if (activeTokenResult.key !== "OPERATION_PROCESSED") {
      debug("SSO Auth code resolved");

      const authToken = activeTokenResult.processingResponse?.authToken;

      if (!authToken) {
        throw new Error("Missing authToken");
      }

      const encryptedToken = tokenHandler.getEncryptedToken(authToken);

      debug("Resoled SSO Token", {
        authTokenLength: authToken.length,
        encryptedTokenLength: encryptedToken.length,
      });

      const cookie = await import("next-cookie").then((res) =>
        res.useCookie(context)
      );

      cookie.set("kca", encryptedToken, {
        httpOnly: true,
        secure: true,
        sameSite: "strict",
      });

      return {
        redirect: {
          destination: userIdentificationSettings?.dualModeEnabled
            ? "/?uis=sso"
            : "/",
          permanent: false,
        },
        props: {}, // keeping props for the inferred type
      };
    }

    throw new Error("Application configuration missing");
  } catch (error) {
    debugger;

    console.error(error);
    debug("Proceeding to generic error");
    return {
      props: {
        errorMessage:
          "There is an error loading the configuration for this screening URL - please try again or contact support",
      },
    };
  }
};

export default Page;
