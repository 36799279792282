import { decomposeColor, useTheme } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Animation } from "./Animation";

let cachedAnimationData: any = null;

const getDataForColor = async (primaryColor: string) => {
  const data = cachedAnimationData || (await import("./check_done.json"));
  cachedAnimationData = data;
  const { values } = decomposeColor(primaryColor);
  const [r, g, b] = values;
  const shapes = [
    {
      i: 8,
      j: 0,
      k: 1,
      a: 1,
    },
    {
      i: 9,
      j: 0,
      k: 1,
      a: 1,
    },
    {
      i: 10,
      j: 0,
      k: 2,
      a: 1,
    },
  ];

  const toUnitVector = (n: number): number =>
    Math.round((n / 255) * 1000) / 1000;

  shapes.forEach((entry) => {
    data.layers[entry.i]!.shapes[entry.j]!.it[entry.k]!.c!.k = [
      toUnitVector(r),
      toUnitVector(g),
      toUnitVector(b),
      entry.a,
    ];
  });

  return data;
};

const DoneIndicator = memo(function MemoDoneIndicator() {
  const theme = useTheme();
  const [animationData, setAnimationData] = useState<any>();
  const [shouldMount, setShouldMount] = useState(false);
  const primaryColor = theme.palette.primary.main;

  useEffect(() => {
    const handleSetAnimation = async () => {
      const data = await getDataForColor(primaryColor);
      setAnimationData(data);
      setShouldMount(true);
    };
    setShouldMount(false);
    handleSetAnimation();
  }, [primaryColor]);

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData,
  };

  if (!shouldMount || !animationData) {
    return null;
  }

  return <Animation {...lottieOptions} />;
});

export default DoneIndicator;
