import { OutlinedInputProps } from "@mui/material";
import type { FieldPath, FieldValues } from "react-hook-form";
import { Controller, ControllerProps } from "react-hook-form";
import { Text } from "../input/Text";
import type { ControlledFieldKeys, FormInputProps } from "./FormInputProps";

type InputComponentProps = OutlinedInputProps;
type BaseProps = Omit<
  InputComponentProps,
  keyof FormInputProps | "id" | ControlledFieldKeys
>;
export interface FormInputTextProps extends BaseProps {
  defaultValue?: InputComponentProps["defaultValue"];
  onEnter?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  focusNextOnEnter?: boolean;
  helperText?: string;
}

export const FormInputText = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  required,
  defaultValue,
  onEnter,
  focusNextOnEnter,
  helperText,
  ...inputProps
}: FormInputTextProps & FormInputProps<TFieldValues, TFieldPath>) => {
  const fullWidth = "fullWidth" in inputProps ? inputProps.fullWidth : true;

  const rules: ControllerProps<TFieldValues, TFieldPath>["rules"] = {};
  if (required) {
    rules.required = "Required";
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as any}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const help = error ? error.message : helperText;
        return (
          <Text
            {...inputProps}
            {...field}
            required={required}
            helperText={help}
            id={`${name}-input`}
            value={field.value || ""}
            label={label}
            fullWidth={fullWidth}
            error={!!error}
          />
        );
      }}
    />
  );
};
