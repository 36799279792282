import { useToast } from "@kenai/core";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  Button,
  ButtonProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  Typography,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { FC, useCallback } from "react";
import {
  InductionProvider,
  InductionProviderProps,
  useInduction,
} from "./InductionProvider";
import InductionQuestions from "./InductionQuestions";
import InductionVideo from "./InductionVideo";

// TODO i18n allow to pass through custom texts
interface InductionProps {
  allowSkip?: boolean;
  nextButtonText?: string;
  loading?: boolean;
}

export { useInduction };

export const Induction: FC<InductionProviderProps & InductionProps> = (
  props
) => {
  return (
    <InductionProvider {...props}>
      <InductionContent {...props} />
    </InductionProvider>
  );
};

function InductionContent(props: InductionProps) {
  const { state, ...induction } = useInduction();

  const videoCompleted = state.videoCompleted;
  const hasQuestions = (induction.inductionQuestions || []).length > 0;

  const title = (
    <Box sx={{ height: 30 }}>
      <Typography variant="body1" className="flex items-center space-x-2">
        {videoCompleted ? (
          <>
            <CheckIcon color="primary" /> {"Induction video completed"}
          </>
        ) : (
          "Watch the whole induction video to proceed."
        )}
      </Typography>
    </Box>
  );

  return (
    <div className="space-y-6 w-full">
      <div>
        <FormLabel sx={{ mb: 1 }}>{title}</FormLabel>
        <InductionVideo />
      </div>

      <div className="space-x-2 flex justify-end">
        {props.allowSkip ? (
          <SkipButton variant={hasQuestions ? "outlined" : "contained"} />
        ) : (
          <LoadingButton
            variant="contained"
            onClick={induction.handleNext}
            disabled={
              !state.videoCompleted ||
              (hasQuestions && !state.questionsCompleted)
            }
            loading={state.loading === "pending" || props.loading}
          >
            {props.nextButtonText || "Next"}
          </LoadingButton>
        )}

        {hasQuestions && <InductionQuestions />}
      </div>
    </div>
  );
}

const SkipButton = ({ variant }: { variant?: ButtonProps["variant"] }) => {
  const { state, ...induction } = useInduction();
  const toast = useToast();
  const hasQuestions = (induction.inductionQuestions || []).length > 0;

  const handleSkip = useCallback(() => {
    if (!state.videoCompleted) {
      // skip the video
      toast("Induction video skipped", {
        variant: "info",
        autoHideDuration: 3000,
      });
      induction.setSkipped(true, "video");
    } else {
      // skip the questions
      toast("Induction questions skipped", {
        variant: "info",
        autoHideDuration: 3000,
      });
      induction.setSkipped(true, "questions");
    }

    induction.handleNext();
  }, [state.videoCompleted, toast, induction]);

  const showVideoRelatedContent = !state.videoCompleted || !hasQuestions;
  const type = showVideoRelatedContent ? "video" : "questions";

  return (
    <PopupState variant="popover" popupId="skip-induction-popover">
      {(popupState) => (
        <div>
          <Button variant={variant} {...bindTrigger(popupState)}>
            Skip
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: -5,
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            aria-labelledby="skip-induction-title"
            aria-describedby="skip-induction-description"
          >
            <DialogTitle id="skip-induction-title">
              {`Are you sure you want to skip the induction ${type}?`}
            </DialogTitle>
            <DialogContent id="skip-induction-description">
              <DialogContentText variant="caption">
                {
                  "* Come back and complete it here later, or on site before your meeting"
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  if (!showVideoRelatedContent) {
                    induction.setOpen(true);
                  }
                  popupState.close();
                }}
              >
                {showVideoRelatedContent ? "No, watch now" : "No, complete now"}
              </Button>
              <Button onClick={handleSkip}>{"Yes, skip"}</Button>
            </DialogActions>
          </Popover>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={popupState.isOpen}
            onClick={() => popupState.close()}
          />
        </div>
      )}
    </PopupState>
  );
};
