import { type SelectProps } from "@mui/material";
import { useCallback, useState } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { Controller, ControllerProps } from "react-hook-form";
import { Select } from "../input/Select";
import { FormInputProps } from "./FormInputProps";

type Option = {
  label: string;
  value: string;
  itemGroupings?: number[];
};

type InputComponentProps = SelectProps<Option>;
type BaseProps = Omit<InputComponentProps, keyof FormInputProps>;

export interface FormInputDropdownProps extends BaseProps {
  options: Option[];
  multiple?: boolean;
  tag?: boolean;
  defaultValue?: InputComponentProps["defaultValue"];
  helperText?: string;
}

export const FormInputDropdown = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  options,
  placeholder,
  multiple,
  readOnly,
  required,
  tag,
  defaultValue,
  ...inputProps
}: FormInputDropdownProps & FormInputProps<TFieldValues, TFieldPath>) => {
  const [visible, setVisible] = useState(false);

  const isGroupedSelection = options.some((option) =>
    "itemGroupings" in option ? Boolean(option.itemGroupings?.[0]) : false
  );

  const getSelectionWithGrouping = useCallback(
    (fieldValue: string[], selectedValue: string) => {
      if (!isGroupedSelection) {
        debugger;
        return fieldValue;
      }
      const { nextValue } = handleSelectWithGroupings(
        fieldValue,
        options,
        selectedValue
      );

      return nextValue;
    },

    [isGroupedSelection, options]
  );
  const parsedDefaultValue = defaultValue || multiple ? ([] as string[]) : "";

  const rules: ControllerProps<TFieldValues, TFieldPath>["rules"] = {};
  if (required) {
    rules.required = "Required";
  }

  const serializeValue = (value?: string | string[]) => {
    if (value) {
      if (multiple && !Array.isArray(value)) {
        return [value];
      }
      return value;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={parsedDefaultValue as any}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const helperText = error ? error.message : inputProps.helperText;
        const fieldValue = serializeValue(field.value ?? defaultValue);

        const handleSingleSelect = (selectionValue: string) => {
          field.onChange(selectionValue);
        };

        const handleMultiSelect = (selectedList: string[], child: any) => {
          const selectionValue = child.props.value; // this is from the MenuItem node in Select component
          const selection = getSelectionWithGrouping(
            selectedList,
            selectionValue
          );
          return field.onChange(selection);
        };

        return (
          <Select
            fullWidth
            name={name}
            label={label}
            open={visible}
            error={!!error}
            disabled={readOnly}
            helperText={helperText}
            displayEmpty={!!placeholder}
            placeholder={placeholder}
            multiple={multiple}
            readOnly={readOnly}
            options={options}
            value={fieldValue as any}
            onOpen={() => setVisible(true)}
            onClose={() => setVisible(false)}
            onChange={
              (!multiple ? handleSingleSelect : handleMultiSelect) as any // TODO typescript - fix the type definition for onChange
            }
          />
        );
      }}
    />
  );
};

export default function handleSelectWithGroupings(
  fieldValue: string[],
  options: Option[],
  selectedValue: string
) {
  let hasGroupedToSingleItem = false;
  let nextValue = [...fieldValue];
  const newItemDef = options.find((item) => item.value === selectedValue);
  if (nextValue.length > 1) {
    // part 3.a handle multiple parts check
    if (newItemDef) {
      nextValue = nextValue.filter((newPart) => {
        if (selectedValue === newPart) return true;
        const existingItemDef = options.find((item) => item.value === newPart);
        if (!existingItemDef) return false;
        if (newItemDef.itemGroupings) {
          //new item has groupings - check whether existing item overlaps
          if (!existingItemDef.itemGroupings) return false;
          let hasOverlap = false;
          newItemDef.itemGroupings.forEach((itemGrouping) => {
            if (
              (existingItemDef.itemGroupings || []).indexOf(itemGrouping) > -1
            ) {
              hasOverlap = true;
            }
          });
          return hasOverlap;
        } else if (
          existingItemDef.itemGroupings &&
          existingItemDef.itemGroupings.length > 0
        ) {
          //existing item has a grouping and new item does not - this implies existing item should be removed as there is no overlap
          return false;
        } else {
          return true; //no groupings
        }
      });
    }
  }
  if (nextValue.length === 1) {
    // part 3.b handle single parts check
    let hasAtLeastOneAdditionalInGroup = false;
    options.forEach((existingItemDef) => {
      if (existingItemDef.value !== newItemDef?.value) {
        if (newItemDef?.itemGroupings?.length) {
          newItemDef.itemGroupings.forEach((itemGrouping) => {
            if (
              existingItemDef.itemGroupings &&
              existingItemDef.itemGroupings.indexOf(itemGrouping) > -1
            ) {
              hasAtLeastOneAdditionalInGroup = true;
            }
          });
        } else {
          if (!existingItemDef.itemGroupings?.length) {
            hasAtLeastOneAdditionalInGroup = true;
          }
        }
      }
    });
    if (!hasAtLeastOneAdditionalInGroup) {
      hasGroupedToSingleItem = true;
    }
  }
  return { nextValue, hasGroupedToSingleItem };
}
