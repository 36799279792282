import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { Controller, ControllerProps } from "react-hook-form";
import { constants } from "./constants";
import { FormInputProps } from "./FormInputProps";
import type { FieldValues, FieldPath } from "react-hook-form";
import { useCallback } from "react";

type Option = { label: string; value: string };

type InputComponentProps = CheckboxProps;
type BaseProps = Omit<InputComponentProps, keyof FormInputProps>;

export interface FormInputCheckboxProps extends BaseProps {
  options: Option[];
  defaultValue?: InputComponentProps["defaultValue"];
  multiple?: boolean;
}

export const FormInputCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  placeholder,
  options,
  readOnly,
  required,
  defaultValue,
  multiple,
  ...inputProps
}: FormInputCheckboxProps & FormInputProps<TFieldValues, TFieldPath>) => {
  const getNewSelection = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      selected: string[] | string
    ) => {
      const checked = event.target.checked;
      const option = event.target.name;
      if (multiple) {
        const selectedOptions = (selected || []) as string[];
        const hasOption = selectedOptions.includes(option);
        if (!checked && hasOption) {
          return selectedOptions.filter?.((item) => item !== option);
        } else if (checked && !hasOption) {
          return [...selectedOptions, option];
        }
      } else {
        if (checked) {
          return option;
        } else {
          return "";
        }
      }
    },
    [multiple]
  );

  const rules: ControllerProps<TFieldValues, TFieldPath>["rules"] = {};
  if (required) {
    rules.required = "Required";
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const helperText = error ? error.message : null;
        return (
          <FormControl
            component="fieldset"
            variant={constants.variant}
            error={!!error}
            disabled={readOnly}
            required={required}
          >
            {label && (
              <FormHelperText component="legend" sx={{ ml: 0 }}>
                {label}
              </FormHelperText> // this uses the same styling as the FormLabel
            )}
            <FormGroup>
              {options.map((option) => {
                const selected = (field.value || []) as string[];
                const checked = selected.includes(option.value);
                return (
                  <FormControlLabel
                    key={option.value}
                    label={option.label}
                    control={
                      <Checkbox
                        {...inputProps}
                        name={option.value}
                        checked={checked}
                        onChange={(event) =>
                          field.onChange(getNewSelection(event, selected))
                        }
                        readOnly={readOnly}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
            {helperText && (
              <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
