import { useAuthentication } from "@/hooks/use-authentication";
import { useInduction } from "@/hooks/use-induction";
import { useStepper } from "@/hooks/use-step";
import { useTextOverrides } from "@/hooks/use-text-overrides";
import { stepper } from "@/slices/stepper";
import { useDispatch } from "@/store";
import { ToolbarStepper } from "@kenai/core";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, HTMLAttributes } from "react";
import EntityLogo from "./EntityLogo";

const useLabelMapper = () => {
  const textOverrides = useTextOverrides();
  return {
    agreement: textOverrides.agreementStepLabel ?? "Agreement",
    authentication: textOverrides.authenticationStepLabel ?? "Authentication",
    induction: textOverrides.inductionStepLabel ?? "Induction",
    summary: textOverrides.summaryStepLabel ?? "Summary",
  };
};

const StepperLayout: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const { currentStep, steps } = useStepper();
  const dispatch = useDispatch();
  const { loading } = useInduction();
  const { processingToken } = useAuthentication();
  const router = useRouter();

  const disableAllSteps = currentStep === "summary";
  const disabledSteps: typeof steps = disableAllSteps ? steps : [];

  if (!disableAllSteps) {
    const inductionDisabled = !processingToken;
    const summaryDisabled = !["success", "failure"].includes(loading);

    if (inductionDisabled) {
      disabledSteps.push("induction");
    }

    if (summaryDisabled) {
      disabledSteps.push("summary");
    }
  }

  return (
    <div>
      <div className="h-[80px] flex items-center justify-center py-4 space-x-6 px-6 bg-slate-50 ">
        <Link href={router.asPath}>
          <a>
            <EntityLogo width={100} height={100} className="max-h-full -ml-4" />
          </a>
        </Link>
        <div className="flex-grow mx-auto">
          <ToolbarStepper
            activeStep={steps.findIndex((s) => s === currentStep)}
            steps={steps}
            labelMapper={useLabelMapper()}
            onChangeStep={(_, step) => {
              dispatch(stepper.setStep(step));
            }}
            disabledSteps={disabledSteps}
          />
        </div>
      </div>
      <main
        {...props}
        className={classNames(
          "overflow-y-auto bg-white h-[calc(100vh-80px)] ",
          props.className
        )}
      >
        <div className="container py-10 md:py-20 max-w-screen-sm min-h-full grid place-items-center sm:-mt-[80px]">
          {children}
        </div>
      </main>
    </div>
  );
};

export default StepperLayout;
