import { FormControl, FormHelperText, OutlinedInputProps } from "@mui/material";
import { forwardRef } from "react";
import OutlinedInput, { OutlinedInputLabel } from "../input/OutlinedInput";

type InputComponentProps = OutlinedInputProps;
export interface TextProps extends InputComponentProps {
  defaultValue?: InputComponentProps["defaultValue"];
  onEnter?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  focusNextOnEnter?: boolean;
  helperText?: string;
}

export const Text = forwardRef<any, TextProps>(
  (
    {
      name,
      label,
      required,
      defaultValue,
      onEnter,
      focusNextOnEnter,
      helperText,
      error,
      ...inputProps
    },
    ref
  ) => {
    const fullWidth = "fullWidth" in inputProps ? inputProps.fullWidth : true;

    return (
      <FormControl
        variant="standard"
        error={!!error}
        disabled={inputProps.readOnly}
        fullWidth={fullWidth}
        hidden={inputProps.hidden}
      >
        <OutlinedInputLabel
          shrink
          htmlFor={`${name}-input`}
          required={required}
        >
          {label}
        </OutlinedInputLabel>
        <OutlinedInput
          {...inputProps}
          ref={ref}
          {...(name && { id: `${name}-input` })}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault(); // Avoids new line because of multiline
              if (focusNextOnEnter) {
                const form = (event.target as any).form;
                if (form) {
                  const index = [...form].indexOf(event.target);
                  const nextInput = form.elements[index + 3];
                  if ("focus" in nextInput) {
                    form.elements[index + 3].focus();
                  }
                }
              }
            }
            if (event.key === "Enter") {
              onEnter?.(event);
            }
            inputProps.onKeyDown?.(event);
          }}
        />
        {helperText && (
          <FormHelperText sx={{ ml: 0 }} color="textSecondary">
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
