import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioProps,
} from "@mui/material";
import { Controller, ControllerProps } from "react-hook-form";
import { constants } from "./constants";
import type { FormInputProps } from "./FormInputProps";
import { FormGroupContainer } from "./FormGroupContainer";
import type { FieldValues, FieldPath } from "react-hook-form";

type Option = { label: string; value: string };
type InputComponentProps = RadioProps;
type BaseProps = Omit<InputComponentProps, keyof FormInputProps>;

export interface FormInputRadioProps extends BaseProps {
  options: Option[];
  direction?: "row" | "column";
  defaultValue?: InputComponentProps["defaultValue"];
  type?: "radio" | "checkbox";
}

export const FormInputRadio = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
  placeholder,
  options,
  readOnly,
  required,
  direction = "row",
  defaultValue,
  ...inputProps
}: FormInputRadioProps & FormInputProps<TFieldValues, TFieldPath>) => {
  const rules: ControllerProps<TFieldValues, TFieldPath>["rules"] = {};
  if (required) {
    rules.required = "Required";
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const helperText = error ? error.message : null;
        return (
          <FormControl
            component="fieldset"
            variant={constants.variant}
            error={!!error}
            disabled={readOnly}
            required={required}
            sx={{ width: "100%" }}
            className={!!error ? "Mui-error" : ""}
          >
            <FormHelperText
              id={`${name}-radio-group-label`}
              component="legend"
              sx={{ ml: 0 }}
            >
              {label} {required && <span aria-hidden>*</span>}
            </FormHelperText>
            <FormGroupContainer>
              <RadioGroup
                aria-labelledby={`${name}-radio-group-label`}
                row={direction === "row"}
                {...field}
                value={field.value || ""} // avoid uncontrolled warning
              >
                {options.map((option) => {
                  return (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      control={
                        <Radio
                          {...inputProps}
                          name={option.value}
                          readOnly={readOnly}
                        />
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormGroupContainer>
            {helperText && (
              <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
